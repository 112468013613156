<template>
  <v-card class="unvergessen-shadow">
    <div class="grey3">
      <h3 style="padding: 10px; text-align: center">
        {{ heading }}
      </h3>
    </div>
    <div>
      <v-container style="padding-top: 0">
        <v-row>
          <div style="font-size: 16px; padding: 20px; cursor: pointer;" @click="pushTo('global')" :class="{ secondary: $route.params.id === 'global' }">
            Gesamt
          </div>
          <div style="font-size: 16px; padding: 20px; cursor: pointer" @click="pushTo('core')" :class="{ secondary: $route.params.id === 'core' }">
            Kern-Produkte
          </div>
          <div style="font-size: 16px; padding: 20px; cursor: pointer" @click="pushTo('memorialMail')" :class="{ secondary: $route.params.id === 'memorialMail' }">
            Gedenk-Post
          </div>
          <div style="font-size: 16px; padding: 20px; cursor: pointer" @click="pushTo('memorialPage')" :class="{ secondary: $route.params.id === 'memorialPage' }">
            Gedenkseiten
          </div>
          <div style="font-size: 16px; padding: 20px; cursor: pointer" @click="pushTo('candle')" :class="{ secondary: $route.params.id === 'candle' }">
            Kerzen
          </div>
          <div style="font-size: 16px; padding: 20px; cursor: pointer" @click="pushTo('memorialProducts')" :class="{ secondary: $route.params.id === 'memorialProducts' }">
            Trauerprodukte
          </div>
          <div style="font-size: 16px; padding: 20px; cursor: pointer" @click="pushTo('marketing')" :class="{ secondary: $route.params.id === 'marketing' }">
            Vermarktung
          </div>
        </v-row>
      </v-container>

      <div style="padding: 10px">
        <apex-chart
          type="line"
          height="350"
          :options="options"
          :series="series"
        ></apex-chart>

        <h3 style="text-align: center" class="mt-6">Kumulierter Umsatz</h3>

        <apex-chart
          type="line"
          height="350"
          :options="options"
          :series="cumulated_series"
        ></apex-chart>
      </div>
    </div>
  </v-card>
</template>

<script>
import ApexChart from 'vue-apexcharts'
import axios from 'axios'

export default {
  props: ['heading', 'chartOptions'],
  data () {
    return {
      invoices: [],
      filteredArray: [],
      amounts: [],
      targetValue: 0,
      monthSize: 31,
      options: {
        labels: []
      },
      series: [
        {
          name: 'Netto-Umsatz (in €)',
          type: 'column',
          data: []
        },
        {
          name: 'Geplanter Umsatz (in €)',
          type: 'line',
          data: []
        }
      ],
      cumulated_series: [
        {
          name: 'Kumulierter Netto-Umsatz (in €)',
          type: 'column',
          data: []
        },
        {
          name: 'Geplanter Umsatz (in €)',
          type: 'line',
          data: []
        }
      ],
      dictionary: {
        candle: ['K'],
        flower: ['FL'],
        memorialMail: ['GP', 'GPE'],
        memorialPage: ['IE', 'FE'],
        core: ['IE', 'FE', 'GP', 'GPE', 'K'],
        marketing: ['VM'],
        global: [],
        memorialProducts: ['TP']
      }
    }
  },
  methods: {
    pushTo (id) {
      this.$router.push({ name: 'ControllingDetails', params: { id: id }, query: { m: this.$route.query.m } }).catch(() => {})
    },
    calculateAmounts () {
      var checkIfIn = element => {
        return (this.dictionary[this.current].includes(element.invoiceNumber.block) && element.status.type !== 'canceled' && element.status.type !== 'storno') || this.dictionary[this.current].length === 0
      }
      this.filteredArray = this.invoices.filter(element => checkIfIn(element))
      var growArr = []

      for (var i = 0; i < this.monthSize; i++) {
        growArr.push(0)
      }
      this.filteredArray.forEach(element => {
        const index = new Date(element._ct).getDate() - 1
        growArr[index] += element.amount
      })
      growArr.forEach((element, i) => {
        growArr[i] = parseFloat(((element / 1.19) / 100).toFixed(2))
      })
      return growArr
    },
    filterGraph () {
      if (!this.$route.query.m) {
        this.monthSize = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
        return {}
      }

      var month = this.$route.query.m

      var data = {}

      // Month
      var d = new Date(new Date().getFullYear(), month)

      data.period = {
        start: new Date(d.getFullYear(), d.getMonth(), 1, 2),
        end: new Date(d.getFullYear(), d.getMonth() + 1, 0, 2)
      }

      this.monthSize = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()

      return data
    }
  },
  created () {
    var data = this.filterGraph()
    axios.post('/controlling/revenueOverview', data)
      .then(res => {
        this.targetValues = res.data
      })
    axios.post('/controlling/details', data)
      .then(res => {
        this.invoices = res.data
        this.amounts = this.calculateAmounts()
        this.targetValue = Math.floor((this.targetValues[this.current].aim / 100) / this.monthSize)
        this.series[0].data = this.amounts
        for (var i = 0; i < this.amounts.length; i++) {
          this.series[1].data[i] = this.targetValue
          this.cumulated_series[0].data[i] = i === 0 ? this.series[0].data[i] : parseFloat((this.cumulated_series[0].data[i - 1] + this.series[0].data[i]).toFixed(2))
          this.cumulated_series[1].data[i] = i === 0 ? this.targetValue : parseFloat((this.cumulated_series[1].data[i - 1] + this.targetValue).toFixed(2))
        }
        this.options = this.amounts
      })
      .catch(() => {})
  },
  watch: {
    current () {
      this.amounts = this.calculateAmounts()
      this.targetValue = Math.floor((this.targetValues[this.current].aim / 100) / this.monthSize)
      this.series[0].data = this.amounts
      for (var i = 0; i < this.amounts.length; i++) {
        this.series[1].data[i] = this.targetValue
        this.cumulated_series[0].data[i] = i === 0 ? this.series[0].data[i] : parseFloat((this.cumulated_series[0].data[i - 1] + this.series[0].data[i]).toFixed(2))
        this.cumulated_series[1].data[i] = i === 0 ? this.targetValue : parseFloat((this.cumulated_series[1].data[i - 1] + this.targetValue).toFixed(2))
      }
      this.options = this.amounts
    }
  },
  computed: {
    current () {
      return this.$route.params.id
    },
    circleColor () {
      return { 'background-color': 'blue' }
    }
  },
  components: {
    apexChart: ApexChart
  }
}
</script>

<style scoped>
</style>
